import roller from 'common/module/ui/roller';

import applicationBootstrap from 'website/bootstrap';
import { socialActionsBootstrap } from 'website/bootstrap/socialActionsBootstrap';
import comments from 'website/containers/generic/comments';
import userRating from 'website/containers/user-action/userRating';
import userWantToSee from 'website/containers/user-action/userWantToSee';
import userFriendsRating from 'website/containers/user/userFriendsRating';
import loadAnchorPlus from 'website/module/anchor/anchor-plus';
import initPlayer from 'website/module/player';
import initPlayerExport from 'website/module/player/export';
import asyncGallery from 'website/module/ui/async-gallery';
import facebookWidgets from 'website/module/ui/facebookWidgets';
// import share
import initMoreTriggerOverlay from 'website/module/ui/more-trigger-overlay';
import thirdNav from 'website/module/ui/third-nav';

// bootstrap the application / store
applicationBootstrap().then(() => {
  initMoreTriggerOverlay();
  initPlayer();
  initPlayerExport();
  facebookWidgets();

  thirdNav();

  // disqus comments init
  comments();

  asyncGallery();

  roller();
  userRating();
  userWantToSee();
  userFriendsRating();
  socialActionsBootstrap();

  loadAnchorPlus();
});
